import axios from "axios";
import store from "@/store";
import router from '@/router';
import { useToast } from "vue-toast-notification";

// const base_url = "http://204.48.21.128/api";
const base_url = process.env.VUE_APP_BASE_URL;

// Create an axios instance
const api = axios.create({
    baseURL: base_url
});

// Add a request interceptor
api.interceptors.request.use((config) => {
    const token = localStorage.getItem('token') || store.state.auth.token

    // If token exists, add it to the Authorization header
    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
}, (error) => {
    return Promise.reject(error);
});

api.interceptors.response.use(
    (response) => response,
    (error) => {
        // const toast = useToast();
        // const { status } = error.response || {};

        // if (!error.response) {
        //     toast.error("Network error. Please check your connection.");
        // } else if (status === 401) {
        //     toast.error("Session expired. Logging out...");
        //     store.dispatch("logout");
        //     router.push("/login");
        // } else if (status === 403) {
        //     toast.error("Access denied. You don't have permission to perform this action.");
        // } else {
        //     toast.error(error.response?.data?.message || "An error occurred. Please try again.");
        // }

        return Promise.reject(error);
    }
);


const getSettingData = () => {
    return api.get('/settings')
}

const requestOtp = (data) => {
    return api.post(`/register-send-otp`, data);
}

const signUp = (data) => {
    return api.post(`/register`, data);
}

const login = (credentials) => {
    return api.post(`/login`, credentials);
}

const getCategories = () => {
    return api.get(`/categories`);
}

const getCategoryCommodities = (id) => {
    return api.get(`/categories/${id}`)
}

const getCommodityDetails = (id, filter) => {
    console.log("filter", filter)
    if (filter.to_date && filter.from_date) {
        return api.get(`commodities/${id}/prices`, {
            params: filter
        })
    } else {
        return api.get(`commodities/${id}/prices`)
    }
}

const makeFavorite = (id) => {
    return api.post(`/commodities/${id}/make-favourite`)
}

const removeFavorite = (id) => {
    return api.post(`/commodities/${id}/remove-favourite`)
}

const getDashboardData = () => {
    return api.get(`/favourite/commodities`)
}

const forgetPassword = (data) => {
    return api.post(`/forgot-password`, data)
}

const resetPassword = (data) => {
    return api.post(`/reset-password`, data);
}

const getSubscriptionPlansData = () => {
    return api.get(`/subscription-plans`)
}

const subscripePlan = (id) => {
    return api.post(`/subscribe/${id}`)
}

const getCountries = () => {
    return api.get(`/countries`);
}

const getCities = (countryId) => {
    return api.get(`/countries/${countryId}/cities`)
}

const remoteSearch = (searchEndPoint, query) => {
    return api.get(`${searchEndPoint}`, query)
}

const createContact = (data) => {
    return api.post(`/contact-us`, data)
}

const goToPayment = (id) => {
    return api.get(`/payment/tokenization/${id}`);
}

const gitPriceTableData = () => {
    return api.get('/changes/commodities')
}

const printCommodity = (id) => {
    return api.get(`/commodities/${id}/export`)
}

const verifyEmail = (id, hash, expires, signature) => {
    return api.get(`/email/verify/${id}/${hash}?expires=${expires}&signature=${signature}`)
}

const resendConfirmationMail = () => {
    return api.post(`/email/resend`)
}

const subscribeNewsLetters = (email) => {
    return api.post(`/newsletter`, { email: email })
}

const stripeSubscribe = (token, id) => {
    return api.post(`/subscribe?token=${token}&plan_id=${id}`)
}

export {
    requestOtp,
    signUp,
    login,
    getCategories,
    getCategoryCommodities,
    getCommodityDetails,
    makeFavorite,
    removeFavorite,
    getDashboardData,
    forgetPassword,
    resetPassword,
    getSubscriptionPlansData,
    subscripePlan,
    getCountries,
    getCities,
    remoteSearch,
    createContact,
    goToPayment,
    gitPriceTableData,
    printCommodity,
    getSettingData,
    verifyEmail,
    resendConfirmationMail,
    subscribeNewsLetters,
    stripeSubscribe
}
