<template>
  <div
    v-if="pageLoader"
    class="h-screen w-full flex items-center justify-center loader"
  >
    <LoadingComponent />
  </div>
  <div v-else>
    <section class="flex items-center justify-center px-10 py-10 gap-10">
      <div class="info">
        <h1 class="font-bold h1-size mb-10 leading-tight">
          Elevate your negotiations to the next level.
        </h1>
        <p class="font-semibold text-xl leading-8 text-schemePrimary-gray">
          Buyers Compass is a commodity data provider to enable buyers finalize
          any sourcing event easily and in an efficient way.
        </p>
        <div
          class="btn-support flex items-center justify-center gap-4 mt-7 text-lg"
        >
          <router-link
            :to="!isAuthenticated ? '/signup' : ''"
            :style="{
              backgroundColor: isAuthenticated
                ? 'rgb(47 120 164 / 68%)'
                : settings?.primary_color,
              cursor: isAuthenticated ? 'not-allowed' : 'pointer',
            }"
            @click="handleClick"
            class="text-white flex items-center gap-2 py-2 px-4 rounded-lg"
          >
            <span> 30 Dayes Free Trial </span>
          </router-link>
          <router-link
            to="contact"
            :style="{
              backgroundColor: settings?.primary_color,
            }"
            class="text-white flex items-center gap-2 py-2 px-4 rounded-lg"
          >
            <span> Contact Us </span>
          </router-link>
        </div>
      </div>
      <div
        class="img rounded-lg flex items-center justify-center overflow-hidden"
      >
        <img
          class="w-full border"
          src="../assets/chart.png"
          style="height: 330px"
          alt="about-img"
        />
      </div>
    </section>
    <div class="spikes"></div>
    <div class="gallery" id="gallery">
      <h2 class="text-center font-bold h1-size mb-10 leading-tight">
        The Categories we serve
      </h2>
      <div class="container">
        <div class="box">
          <div class="img">
            <img src="../assets/energy.png" alt="energy logo" />
          </div>
          <div class="title">
            <h4
              class=""
              :style="{
                color: settings?.primary_color,
              }"
            >
              Energy
            </h4>
            <p
              :style="{
                color: settings?.primary_color,
              }"
            >
              Natural Gas, TTF Gaz, Gazoline, Heating Oil, Propane ...
            </p>
          </div>
        </div>

        <div class="box">
          <div class="img">
            <img src="../assets/industrial.png" alt="industrial logo" />
          </div>
          <div class="title">
            <h4
              :style="{
                color: settings?.primary_color,
              }"
            >
              Industrial
            </h4>
            <p
              :style="{
                color: settings?.primary_color,
              }"
            >
              Aluminum, Zinc, Lead, Cobalt, Tin, Nickel, Magnesium ...
            </p>
          </div>
        </div>

        <div class="box">
          <div class="img">
            <img src="../assets/metals.png" alt="metals logo" />
          </div>
          <div class="title">
            <h4
              :style="{
                color: settings?.primary_color,
              }"
            >
              Metals
            </h4>
            <p
              :style="{
                color: settings?.primary_color,
              }"
            >
              Gold, Silver, Steel, HRC Steel, Copper, Platinum, Titanium ...
            </p>
          </div>
        </div>

        <div class="box">
          <div class="img">
            <img src="../assets/agriculture.png" alt="agriculture logo" />
          </div>
          <div class="title">
            <h4
              :style="{
                color: settings?.primary_color,
              }"
            >
              Agriculture
            </h4>
            <p
              :style="{
                color: settings?.primary_color,
              }"
            >
              Coffe, Cocoa, Tea, Milk, Oat, Rice, Corn, Cheese, Palm Oil ...
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="pt-10 pb-5" v-if="subscriptionData">
      <h2 class="text-center font-bold h1-size mb-4">Subscription Plans</h2>
      <PricingPlans
        :subscriptionsData="subscriptionData"
        @yearlySubscribe="subscribeYearPlan"
        @monthlySubscribe="subscribeMonthPlan"
      />
      <div id="form-test"></div>
    </div>
    <div>
      <div class="subscribe">
        <section class="flex items-center justify-center flex-col py-16">
          <div class="compass shadow-md bg-white p-10">
            <h3 class="font-bold text-3xl mb-9">Contact</h3>
            <p class="text-2xl font-semibold mb-7">
              I'm always looking for new and exciting opportunities. Let's
              connect.
            </p>
            <a
              href="mailto:info@mysite.com"
              target="_self"
              class="text-xl"
              :style="{
                color: settings?.primary_color,
              }"
              >support@buyers-compass.com</a
            >
            <div class="subscribe">
              <form action="">
                <input
                  type="email"
                  v-model="v$.subscribe.$model"
                  :class="{
                    'is-invalid': v$.subscribe.$invalid && v$.subscribe.$dirty,
                  }"
                  @blur="v$.subscribe.$touch"
                  placeholder="Enter You Email"
                />
                <input
                  type="submit"
                  @click.prevent="handleNewsletter"
                  :style="{
                    backgroundColor: settings?.primary_color,
                  }"
                  value="Subscribe"
                />
              </form>
              <span
                v-if="v$.subscribe.email.$invalid && v$.subscribe.$dirty"
                class="text-danger text-center absolute bottom-2 left-32"
                >Invalid email</span
              >
            </div>
          </div>
        </section>
      </div>
    </div>
    <Footer />
    <ScrollToTop :showAfterScroll="200" />
  </div>
</template>

<script>
import PricingPlans from "@/components/layout/PricingPlans.vue";
import LoadingComponent from "@/components/general/LoadingComponent.vue";
import ScrollToTop from "@/components/general/ScrollToTop.vue";
import Footer from "@/components/general/Footer.vue";
import { useVuelidate } from "@vuelidate/core";
import { email } from "@vuelidate/validators";
import {
  getSubscriptionPlansData,
  subscribeNewsLetters,
  goToPayment,
} from "@/services/apis";

import { mapGetters } from "vuex";

export default {
  name: "HomeView",
  components: { Footer, PricingPlans, LoadingComponent, ScrollToTop },
  setup: () => ({ v$: useVuelidate() }),
  data() {
    return {
      subscribe: "",
      pageLoader: false,
      subscriptionData: null,
      settings: null,
    };
  },
  validations() {
    return {
      subscribe: { email },
    };
  },
  created() {
    this.settings = JSON.parse(localStorage.getItem("settings"));
  },
  computed: {
    ...mapGetters({
      isAuthenticated: "isAuthenticated",
    }),
  },
  mounted() {
    this.pageLoader = true;

    getSubscriptionPlansData()
      .then((res) => {
        if (res.data.data) {
          this.subscriptionData = res.data.data;
        }
      })
      .catch((err) => {
        console.log("err", err);
      })
      .finally(() => {
        this.pageLoader = false;
      });
  },
  methods: {
    handleNewsletter() {
      this.pageLoader = true;
      subscribeNewsLetters(this.subscribe)
        .then((res) => {
          this.$toast.success(res.data.message);
        })
        .catch((err) => {
          console.log(err);
          this.$toast.error(
            err.response?.data?.message || "An error occurred "
          );
        })
        .finally(() => {
          this.pageLoader = false;
        });
    },
    subscribeMonthPlan(id) {
      console.log("parent ", id);
      if (this.isAuthenticated) {
        this.$router.push(`/stripe/${id}`);
        // goToPayment(id)
        //   .then((res) => {
        //     this.formHtml = res.data.data.form;
        //     document.getElementById("form-test").innerHTML = this.formHtml;

        //     if (this.formHtml) {
        //       this.submitForm();
        //     }
        //   })
        //   .catch((err) => {
        //     console.log("err", err);
        //   });
      } else {
        this.$router.push("/signup");
      }
    },
    subscribeYearPlan(id) {
      if (this.isAuthenticated) {
        this.$router.push(`/stripe/${id}`);
        // goToPayment(id)
        //   .then((res) => {
        //     this.formHtml = res.data.data.form;
        //     document.getElementById("form-test").innerHTML = this.formHtml;

        //     if (this.formHtml) {
        //       this.submitForm();
        //     }
        //   })
        //   .catch((err) => {
        //     console.log("error", err);
        //   });
      } else {
        this.$router.push("/signup");
      }
    },
    submitForm() {
      document.getElementById("submit").click();
    },
    handleClick(event) {
      if (this.isAuthenticated) {
        event.preventDefault();
      }
    },
  },
};
</script>

<style scoped>
.h1-size {
  font-size: var(--h1-size);
}
.info {
  width: 580px;
}

.img {
  width: 550px;
  height: 480px;
}
.clip-section {
  clip-path: polygon(0 10%, 100% 0, 100% 90%, 0 100%);
}
.gallery {
  padding-top: 60px;
  padding-bottom: 40px;
  background-color: #f5f5f5;
}

.gallery .container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
  gap: 20px;
}

.gallery .box {
  height: 320px;
  overflow: hidden;
  position: relative;
  padding: 15px;
  background-color: #fff;
  box-shadow: 0 12px 20px 0 rgb(0 0 0 / 13%), 0 2px 4px 0 rgb(0 0 0 / 12%);
  -webkit-box-shadow: 0 12px 20px 0 rgb(0 0 0 / 13%),
    0 2px 4px 0 rgb(0 0 0 / 12%);
  -moz-box-shadow: 0 12px 20px 0 rgb(0 0 0 / 13%), 0 2px 4px 0 rgb(0 0 0 / 12%);
  -o-box-shadow: 0 12px 20px 0 rgb(0 0 0 / 13%), 0 2px 4px 0 rgb(0 0 0 / 12%);
}
.gallery .box .title {
  position: absolute;
  left: 0;
  bottom: 0;
  padding: 10px 20px;
  width: 100%;
  height: 100px;
  transition: 0.3s;
  text-align: center;
  font-size: 30px;
  font-weight: bold;
  letter-spacing: 2px;
  background-color: #f5f5f569;
  z-index: 99999;
}

.gallery .box .title p {
  position: absolute;
  bottom: -70px;
  text-align: center;
  font-size: 16px;
  letter-spacing: 1px;
  transition: all 0.5s ease-in-out;
}

.gallery .box:hover .title p {
  bottom: 15px;
}

.gallery .box .img {
  position: relative;
  overflow: hidden;
  top: -50px;
}

.gallery .box .img::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgb(225 225 225 / 0.2);
  width: 0;
  height: 0;
  opacity: 0;
  z-index: 2;
}

.gallery .box .img:hover::before {
  animation: flashing 0.7s;
}

.gallery .box img {
  max-width: 100%;
  transition: 0.3s;
}

.gallery .box .img:hover img {
  transform: rotate(5deg) scale(1.1);
}

.spikes {
  position: relative;
}

.spikes::after {
  content: "";
  position: absolute;
  right: 0;
  width: 100%;
  height: 30px;
  z-index: 2;
  background-image: linear-gradient(135deg, #fff 25%, transparent 25%),
    linear-gradient(225deg, #fff 25%, transparent 25%);
  background-size: 30px 30px;
}

.subscribe {
  width: 100%;
  margin-top: 50px;
  position: relative;
}
.subscribe form {
  width: 600px;
  margin: 20px auto;
  padding: 30px 40px;
  background-color: #fff;
  border-radius: 50px;
  display: flex;
  align-items: center;
  gap: 20px;
}
@media (max-width: 767px) {
  .subscribe form {
    max-width: 100%;
    padding: 20px;
    flex-direction: column;
    border-radius: 6px;
  }
}
.subscribe input[type="email"] {
  padding: 20px;
  border-radius: 50px;
  border: none;
  flex: 1;
  background-color: #f5f5f5;
  border: 2px solid #f5f5f5;
}

@media (max-width: 767px) {
  .subscribe input[type="email"] {
    border-radius: 6px;
    width: 100%;
  }
}

.subscribe input[type="email"]:focus {
  outline: none;
}

.subscribe input[type="email"]::placeholder {
  transition: opacity 0.3s;
}

.subscribe input[type="email"]:focus::placeholder {
  opacity: 0;
}

.subscribe input[type="submit"] {
  border-radius: 50px;
  border: none;
  color: #fff;
  padding: 20px;
  font-weight: bold;
  cursor: pointer;
  transition: 0.3s;
}

@media (max-width: 767px) {
  .subscribe input[type="submit"] {
    border-radius: 6px;
    width: 100%;
  }
}

.subscribe input[type="submit"]:hover {
  background-color: var(--primary-color);
}
.is-invalid {
  border-color: #e3342f;
}
.text-danger {
  color: #e3342f;
  margin-top: 0.25rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
}

@keyframes flashing {
  0%,
  40% {
    opacity: 1;
  }

  100% {
    opacity: 0;
    width: 200%;
    height: 200%;
  }
}
</style>
